<div class="wrapper about_contact_bg">
  <div class="row">
    <div class="col-md-10 mx-auto about_contact_margin text-center">
      <div class="row">
        <div class="col-sm-6 col-md-4 mx-auto">
          <img class="mt-5" src="../../../assets/about_page/01.svg" alt="" />
          <h3 class="mb-0 mt-4 title">Product Feedback</h3>
          <p class="mb-0 text">Product@coinmarket.com</p>
        </div>
        <div class="col-sm-6 col-md-4 mx-auto">
          <img class="mt-5" src="../../../assets/about_page/02.svg" alt="" />
          <h3 class="mb-0 mt-4 title">PR & Marketing</h3>
          <p class="mb-0 text">Marketing@coinmarket.com</p>
        </div>
        <div class="col-sm-6 col-md-4 mx-auto">
          <img class="mt-5" src="../../../assets/about_page/03.svg" alt="" />
          <h3 class="mb-0 mt-4 title">Business Development</h3>
          <p class="mb-0 text">Business@coinmarket.com</p>
        </div>
        <div class="col-sm-6 col-md-4 mx-auto">
          <img class="mt-5" src="../../../assets/about_page/04.svg" alt="" />
          <h3 class="mb-0 mt-4 title">OTC</h3>
          <p class="mb-0 text">Tradedesk1@coinmarket.com</p>
        </div>
        <div class="col-sm-6 col-md-4 mx-auto">
          <img class="mt-5" src="../../../assets/about_page/05.svg" alt="" />
          <h3 class="mb-0 mt-4 title">Cryptoaccess</h3>
          <p class="mb-0 text">Crypto@coinmarket.com</p>
        </div>
        <div class="col-sm-6 col-md-4 mx-auto">
          <img class="mt-5" src="../../../assets/about_page/08.svg" alt="" />
          <h3 class="mb-0 mt-4 title">Banking & Vendor Enquiries</h3>
          <p class="mb-0 text">Risk@coinmarketcom</p>
        </div>
      </div>
    </div>
  </div>
</div>
