<div class="row">
  <div class="col-lg-10 mx-auto freedom_margin">
    <div class="row">
      <div
        class="col-md-6 col-xl-7 align-items-center d-flex justify-content-center p-4"
      >
        <div class="">
          <h1 class="freedom_title">Global Freedom</h1>
          <p class="freedom_text mt-5">
            Our vision is to increase the freedom of money globally. We beleive
            that by spraeding this freedom, we can significantly improve lives
            around the world.
          </p>
          <h3 class="mt-5 freedom_footer">The Coinmarket Vision</h3>
        </div>
      </div>
      <div class="col-md-6 col-xl-5 d-flex gallery">
        <div
          class="position_relative d-flex justify-content-center mx-auto w-100"
        >
          <div class="">
            <img
              src="../../../assets/about_page/Ellipse 1.png"
              class="img_1"
              alt=""
            />
            <img
              src="../../../assets/about_page/Ellipse 2.png"
              class="img_2"
              alt=""
            />
            <img
              src="../../../assets/about_page/Ellipse 3.png"
              class="img_3"
              alt=""
            />
            <img
              src="../../../assets/about_page/Ellipse 4.svg"
              class="blue_ball"
              alt=""
            />
            <img
              src="../../../assets/about_page/Ellipse 5.svg"
              class="yellow_ball"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
