<div class="row">
  <div class="col-md-10 mx-auto future_tech_margin">
    <h4 class="header">Life at Coinmarket</h4>
    <h1 class="mt-4 title">Build the future of technology</h1>
    <p class="mt-4 text">
      What’s the secret to finances success? The answer is obvious. Our people.
      We should be proud to have one of the most talented, hardworking and
      passionate teams the world has to offer. Interested in joining oyr team?
    </p>
    <h4 class="footer_title mt-4">Check for openings</h4>
  </div>
</div>
