<div class="row">
  <div class="col-11 mx-auto contact_margin">
    <h1 class="title text-center">Contact Coinmarket</h1>
    <p class="text text-center mt-4">
      Beyond operating the easiest leading cryptocurrency exchange. <br />
      CoinMarket spans an entire ecosystem.
    </p>
    <div class="row mt-5 pt-5">
      <div class="col-lg-4">
        <div class="left_pane">
          <h3 class="touch_title">Get in touch</h3>
          <p class="touch_text">
            Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat
            massa dictumst amet. Sapien tortor lacus arcu.
          </p>
          <p class="touch_text mt-5 mb-0">742 Evergreen Terrace</p>
          <p class="touch_text mb-0">Springfield, OR 12345</p>
          <div class="mt-4 d-flex align-items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.22792 3.68377L8.27924 4L8.27924 4L9.22792 3.68377ZM10.7257 8.17721L9.77705 8.49344L10.7257 8.17721ZM10.2243 9.38787L10.6715 10.2823L10.6715 10.2823L10.2243 9.38787ZM7.96701 10.5165L7.51979 9.62207C7.03975 9.86209 6.83479 10.4383 7.05538 10.9275L7.96701 10.5165ZM13.4835 16.033L13.0725 16.9446C13.5618 17.1652 14.1379 16.9602 14.3779 16.4802L13.4835 16.033ZM14.6121 13.7757L13.7177 13.3285L14.6121 13.7757ZM15.8228 13.2743L15.5066 14.2229L15.5066 14.2229L15.8228 13.2743ZM20.3162 14.7721L20.6325 13.8234L20.3162 14.7721ZM5 2C3.34315 2 2 3.34315 2 5H4C4 4.44772 4.44772 4 5 4V2ZM8.27924 2H5V4H8.27924V2ZM10.1766 3.36755C9.90438 2.55086 9.1401 2 8.27924 2V4L8.27924 4L10.1766 3.36755ZM11.6744 7.86098L10.1766 3.36754L8.27924 4L9.77705 8.49344L11.6744 7.86098ZM10.6715 10.2823C11.5617 9.83718 11.9892 8.80521 11.6744 7.86098L9.77705 8.49344L9.77705 8.49344L10.6715 10.2823ZM8.41422 11.4109L10.6715 10.2823L9.77705 8.49344L7.51979 9.62207L8.41422 11.4109ZM13.8945 15.1214C11.6721 14.1194 9.88063 12.3279 8.87863 10.1055L7.05538 10.9275C8.25786 13.5946 10.4054 15.7421 13.0725 16.9446L13.8945 15.1214ZM13.7177 13.3285L12.5891 15.5858L14.3779 16.4802L15.5066 14.2229L13.7177 13.3285ZM16.139 12.3256C15.1948 12.0108 14.1628 12.4383 13.7177 13.3285L15.5066 14.2229L15.5066 14.2229L16.139 12.3256ZM20.6325 13.8234L16.139 12.3256L15.5066 14.2229L20 15.7208L20.6325 13.8234ZM22 15.7208C22 14.8599 21.4491 14.0956 20.6325 13.8234L20 15.7208H22ZM22 19V15.7208H20V19H22ZM19 22C20.6569 22 22 20.6569 22 19H20C20 19.5523 19.5523 20 19 20V22ZM18 22H19V20H18V22ZM2 6C2 14.8366 9.16344 22 18 22V20C10.268 20 4 13.732 4 6H2ZM2 5V6H4V5H2Z"
                fill="#452B86"
              />
            </svg>
            <p class="touch_text ms-3 mb-0">+1 (555) 123-4567</p>
          </div>
          <div class="d-flex align-items-center mt-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z"
                stroke="#452B86"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="touch_text ms-3 mb-0">support@example.com</p>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <form action="">
          <div class="row">
            <div class="col-12">
              <input
                class="ps-3"
                type="text"
                name=""
                placeholder="Full Name"
                id=""
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-5">
              <input
                class="ps-3"
                type="email"
                name=""
                placeholder="Email"
                id=""
              />
            </div>
            <div class="col-md-6 mt-5">
              <input
                class="ps-3"
                type="tel"
                name=""
                placeholder="Phone"
                id=""
              />
            </div>
            <div class="col-12">
              <label for="" class="label mt-4">Let’s get started</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Select an option</option>
                <option value="1">Option One</option>
              </select>
            </div>
            <div class="col-12">
              <label for="" class="label mt-4">What is your issue about?</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Select an option</option>
                <option value="1">Option Two</option>
              </select>
            </div>
            <div class="col-12">
              <label for="" class="label mt-4"
                >What seems to be the problem?</label
              >
              <select class="form-select" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">Option Three</option>
              </select>
            </div>
            <div class="col-12">
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Additional Message"
                class="textarea mt-4 ps-3 pt-3"
              ></textarea>
            </div>
          </div>
          <button type="submit" class="submit mt-3">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>
