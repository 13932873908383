import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutIndexComponent } from './about/about-index/about-index.component';
import { ContactIndexComponent } from './contact/contact-index/contact-index.component';
import { IndexComponent } from './home/index/index.component';
import { LoginIndexComponent } from './login/login-index/login-index.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
  },
  {
    path: 'about',
    component: AboutIndexComponent,
  },
  {
    path: 'contact',
    component: ContactIndexComponent,
  },
  {
    path: 'login',
    component: LoginIndexComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
