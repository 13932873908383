import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-currency-dashboard',
  templateUrl: './currency-dashboard.component.html',
  styleUrls: ['./currency-dashboard.component.css']
})
export class CurrencyDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
