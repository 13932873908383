import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trusted-platform',
  templateUrl: './trusted-platform.component.html',
  styleUrls: ['./trusted-platform.component.css']
})
export class TrustedPlatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
