<div class="wrapper touch_bg">

    <div class="row">
      <div class="col-md-10 text-center touch_platform_margin mx-auto">
        <h1 class="touch_header_title">Get in touch. Stay in touch.</h1>
        <div class="row mt-5">
          <div class="col-md-4">
            <h3 class="touch_sub_header">24 / 7 Support</h3>
            <p class="touch_text">
              Got a problem? Just get in touch. Our support team is available 24/7.
            </p>
          </div>
          <div class="col-md-4">
            <h3 class="touch_sub_header">Coinmarket Blog</h3>
            <p class="touch_text">
              News and updates from the easiest leading cryptocuurrency exchange.
            </p>
          </div>
          <div class="col-md-4">
            <h3 class="touch_sub_header">Community</h3>
            <p class="touch_text">
              Coinmarket is global. Join the discussion in our facebook channel.
            </p>
          </div>
        </div>
      </div>
    </div>
</div>
