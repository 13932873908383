<div class="row">
  <div class="col-md-10 text-center trusted_platform_marggin mx-auto">
    <h1 class="header_title">The most trusted cryptocurrency platform</h1>
    <p class="mt-3 trust_header_text">
      Here are a few reasons why you should choose CoinMarket
    </p>
    <div class="row mt-5">
      <div class="col-md-4">
        <img src="../../../assets/trusted_platform/safe 1.svg" alt="" />
        <h3 class="trust_sub_header">Secure Storage</h3>
        <p class="trust_text">
          We store the majority of the digital assets in secure offline
          storage..
        </p>
      </div>
      <div class="col-md-4">
        <img src="../../../assets/trusted_platform/Page 1.svg" alt="" />
        <h3 class="trust_sub_header">Protected by insurance</h3>
        <p class="trust_text">
          Crptocurrency stored inour server is covered by our insurance policy.
        </p>
      </div>
      <div class="col-md-4">
        <img src="../../../assets/trusted_platform/shield 1.svg" alt="" />
        <h3 class="trust_sub_header">Industry best practices</h3>
        <p class="trust_text">
          CoinMarket supports a variaty of the most popular digital currencies.
        </p>
      </div>
    </div>
  </div>
</div>
