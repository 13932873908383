<div class="row">
  <div class="col-12">
    <div class="d-flex align-items-center justify-content-center header_bg">
      <div class="">
        <h1 class="header_title">Trade Crypto in minutes</h1>
        <p class="mt-3 header_text">
          Join the easiest largest crypto exchange
        </p>
        <div class="d-flex align-items-center justify-content-center mt-5 sm_block">
          <input
            class="header_email_input ps-3"
            type="text"
            name=""
            id=""
            placeholder="Email Address/Phone Number"
          />
          <button class="header_submit_btn ms-2" type="submit">Register Now</button>
        </div>
      </div>
    </div>
  </div>
</div>
