<div class="wrapper">
  <div class="header_bg">
    <app-navbar></app-navbar>
    <app-about-header></app-about-header>
  </div>
  <app-showcase></app-showcase>
  <app-global-freedom></app-global-freedom>
  <app-about-contact></app-about-contact>
  <div class="future_tech_bg">
    <app-future-tech></app-future-tech>
  </div>
  <app-start-trading></app-start-trading>
  <app-footer></app-footer>
</div>
