<div class="showcase_bg">
  <div class="row">
    <div class="col-md-10 mx-auto showcase_margin">
      <div class="row text-center">
        <div class="col-md-6 col-xl-4 mx-auto">
          <h1 class="showcase_title">2.0 bn</h1>
          <p class="showcase_text mt-4">Averagr daily volume</p>
        </div>
        <div class="col-md-6 col-xl-4 mx-auto">
          <h1 class="showcase_title">1,400,000+</h1>
          <p class="showcase_text mt-4">Transactions per second</p>
        </div>
        <div class="col-md-6 col-xl-4 mx-auto">
          <h1 class="showcase_title">24/7</h1>
          <p class="showcase_text mt-4">Support</p>
        </div>
      </div>
    </div>
  </div>
</div>
