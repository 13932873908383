<div class="row">
  <div class="col-11 mx-auto border-bottom">
    <div class="d-flex align-items-center login_margin justify-content-center">
      <div class="">
        <h1 class="title">Sign in to Coinmarket</h1>
        <div class="sign_in_area mt-3 mb-3 ps-5 pe-5 pt-4 pb-4">
          <label for="">Email</label>
          <input class="input_field" type="email" name="" id="" />
          <label class="mt-4" for="">Password</label>
          <input class="input_field" type="password" name="" id="" />
          <div class="d-flex align-items-center justify-content-between mt-4">
            <div class="d-flex align-items-center">
              <input type="checkbox" name="" id="" />
              <p class="remember_me mb-0 ms-1">Remember me</p>
            </div>
            <a href="#" class="remember_me mb-0">Forgot your password?</a>
          </div>
          <button type="submit" class="submit mt-4">Sign in</button>
          <a href="#">
            <p class="mb-0 mt-5 remember_me text-center">
              Don’t have an account?
            </p>
          </a>
          <a href="#">
            <p class="mb-0 mt-4 privacy mb-3">Privacy Policy</p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <p class="footer_text text-center mt-4 mb-4">
      © 2021 Coinmarket. All rights reserved.
    </p>
  </div>
</div>
