<nav class="navbar navbar-expand-lg navbar_color navbar-dark">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/">
      <img src="../../../assets/navbar/coinmarket-04 1.svg" alt="" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse mobile_navbar_color"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown ms-5 clear_margin">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Trade <span class="usd ms-1 me-2">USD</span>
          </a>
          <ul
            class="dropdown-menu custom_dropdown"
            aria-labelledby="navbarDropdown"
          >
            <div
              class="d-flex align-items-center justify-content-between ms-3 mt-2 me-3 mb-3"
            >
              <p class="payment_text mb-0">Pay with</p>
              <span class="d-flex align-items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C3.58136 0 0 3.58171 0 8C0 12.4183 3.58136 16 8 16C12.4186 16 16 12.4179 16 8C16 3.58205 12.4183 0 8 0ZM8.48427 11.5636V12.4897C8.48427 12.6341 8.37182 12.7393 8.22699 12.7393H7.60973C7.4649 12.7393 7.34827 12.6341 7.34827 12.4897V11.6451C6.7801 11.6193 6.20706 11.4982 5.77327 11.3331C5.53827 11.244 5.40737 10.9923 5.46969 10.7486L5.5703 10.3566C5.60547 10.2191 5.69738 10.1042 5.82306 10.0398C5.94874 9.97432 6.09635 9.96771 6.2283 10.0182C6.64154 10.178 7.12964 10.2943 7.65081 10.2943C8.31437 10.2943 8.7687 10.038 8.7687 9.57222C8.7687 9.12973 8.39619 8.85017 7.53418 8.55877C6.28818 8.13961 5.43488 7.55751 5.43488 6.42778C5.43488 5.40319 6.16006 4.59968 7.3998 4.35493V3.51034C7.3998 3.36586 7.52339 3.23635 7.66822 3.23635H8.28548C8.43031 3.23635 8.53649 3.36586 8.53649 3.51034V4.27312C9.07855 4.29644 9.46986 4.37687 9.79712 4.4834C10.0447 4.56417 10.1909 4.82353 10.1258 5.07629L10.0373 5.42722C10.0029 5.5609 9.91444 5.6744 9.79329 5.73985C9.67214 5.8053 9.52905 5.81714 9.39815 5.77223C9.10083 5.67022 8.713 5.57761 8.22142 5.57761C7.46455 5.57761 7.21981 5.90383 7.21981 6.23004C7.21981 6.61439 7.62749 6.85878 8.61726 7.23165C10.0029 7.72079 10.5596 8.36137 10.5596 9.40894C10.5592 10.4454 9.82706 11.331 8.48427 11.5636Z"
                    fill="#F7CD29"
                  />
                </svg>
                <a href="#" class="usd_dropdown ms-2 me-2">USD</a>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.33337L5.66667 6.00004L1 10.6667"
                    stroke="#3F3F46"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <li class="pb-1 pt-1">
              <a class="dropdown-item border-bottom" href="#">
                <span class="d-flex align-items-start">
                  <svg
                    class="mt-2"
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2.5" cy="2.5" r="2.5" fill="#FCC019" />
                  </svg>
                  <span class="ms-2">
                    <p class="dropdown_text mb-0">Bank Deposit</p>
                    <p class="dropdown_text_sub mb-0">SWIFT Bank Transfer</p>
                  </span>
                </span>
              </a>
            </li>
            <li class="pb-1 pt-1">
              <a class="dropdown-item border-bottom" href="#">
                <span class="d-flex align-items-start">
                  <svg
                    class="mt-2"
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2.5" cy="2.5" r="2.5" fill="#FCC019" />
                  </svg>
                  <span class="ms-2">
                    <p class="dropdown_text mb-0">Credit/Debit Card</p>
                    <p class="dropdown_text_sub mb-0">Visa, Mastercard</p>
                  </span>
                </span>
              </a>
            </li>
            <li class="pb-1 pt-1">
              <a class="dropdown-item border-bottom" href="#">
                <span class="d-flex align-items-start">
                  <svg
                    class="mt-2"
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2.5" cy="2.5" r="2.5" fill="#FCC019" />
                  </svg>
                  <span class="ms-2">
                    <p class="dropdown_text mb-0">P2P Trading</p>
                    <p class="dropdown_text_sub mb-0">
                      Bank transfer and 100+ options
                    </p>
                  </span>
                </span>
              </a>
            </li>
            <li class="pb-1 pt-1">
              <a class="dropdown-item border-bottom" href="#">
                <span class="d-flex align-items-start">
                  <svg
                    class="mt-2"
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2.5" cy="2.5" r="2.5" fill="#FCC019" />
                  </svg>
                  <span class="ms-2">
                    <p class="dropdown_text mb-0">Third-party Payment</p>
                    <p class="dropdown_text_sub mb-0">Paxos</p>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/about">About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contact">Contact Us</a>
        </li>
      </ul>
      <span class="d-flex align-items-center">
        <ul class="navbar-nav">
          <li class="nav-item me-3">
            <a class="nav-link" routerLink="/login">Log In</a>
          </li>
        </ul>
        <button class="register_btn" type="submit" routerLink="/login">
          Register
        </button>
      </span>
    </div>
  </div>
</nav>
