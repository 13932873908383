import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-freedom',
  templateUrl: './global-freedom.component.html',
  styleUrls: ['./global-freedom.component.css']
})
export class GlobalFreedomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
