import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-index',
  templateUrl: './about-index.component.html',
  styleUrls: ['./about-index.component.css']
})
export class AboutIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
