<div class="row">
  <div class="col-12 currency_dashboard_bg">
    <div class="currency_dashboard_margin">
      <h1 class="header_title">Buy flat currencies directly on Coinmarket.</h1>
      <div class="row">
        <div class="col-11 mx-auto mt-5">
          <div class="table-responsive">
            <table class="table table-striped table-borderless" id="table">
              <thead>
                <tr class="table_header_row">
                  <th class="table_header ps-4" scope="col">Name</th>
                  <th class="table_header" scope="col">Last Price</th>
                  <th class="table_header" scope="col">24h Change</th>
                  <th class="table_header" scope="col">Markets</th>
                  <th
                    class="table_header d-flex justify-content-center pt-3"
                    scope="col"
                  >
                    Trade
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <div class="d-flex align-items-center mt-2 ps-3">
                      <img src="../../../assets/coins_icon/BNB 1.svg" alt="" />
                      <p class="mb-0 coin_abb ms-3 me-3">BNB</p>
                      <p class="mb-0 coin_name">BNB</p>
                    </div>
                  </th>
                  <td class="last_price">₦ 22,500,123.10</td>
                  <td class="price_change negative">-8.5%</td>
                  <td>
                    <img
                      src="../../../assets/coins_icon/Screenshot 2021-02-28 at 15.09 1.svg"
                      alt=""
                    />
                  </td>
                  <td class="d-flex justify-content-center">
                    <button class="buy_btn">Buy</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="d-flex align-items-center mt-2 ps-3">
                      <img src="../../../assets/coins_icon/BTC 1.svg" alt="" />
                      <p class="mb-0 coin_abb ms-3 me-3">BTC</p>
                      <p class="mb-0 coin_name">Bitcoin</p>
                    </div>
                  </th>
                  <td class="last_price">₦ 17,814,598.27</td>
                  <td class="price_change positive">+5.08%</td>
                  <td>
                    <img
                      src="../../../assets/coins_icon/Screenshot 2021-02-28 at 15.09 1.svg"
                      alt=""
                    />
                  </td>
                  <td class="d-flex justify-content-center">
                    <button class="buy_btn">Buy</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="d-flex align-items-center mt-2 ps-3">
                      <img src="../../../assets/coins_icon/ETH 1.svg" alt="" />
                      <p class="mb-0 coin_abb ms-3 me-3">ETH</p>
                      <p class="mb-0 coin_name">Ethereum</p>
                    </div>
                  </th>
                  <td class="last_price">₦ 572,572.70</td>
                  <td class="price_change negative">-5.08%</td>
                  <td>
                    <img
                      src="../../../assets/coins_icon/Screenshot 2021-02-28 at 15.09 1.svg"
                      alt=""
                    />
                  </td>
                  <td class="d-flex justify-content-center">
                    <button class="buy_btn">Buy</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="d-flex align-items-center mt-2 ps-3">
                      <img src="../../../assets/coins_icon/DOGE 1.svg" alt="" />
                      <p class="mb-0 coin_abb ms-3 me-3">DOGE</p>
                      <p class="mb-0 coin_name">Dogecoin</p>
                    </div>
                  </th>
                  <td class="last_price">₦ 32,543,89</td>
                  <td class="price_change positive">+5.08%</td>
                  <td>
                    <img
                      src="../../../assets/coins_icon/Screenshot 2021-02-28 at 15.09 1.svg"
                      alt=""
                    />
                  </td>
                  <td class="d-flex justify-content-center">
                    <button class="buy_btn">Buy</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <div class="d-flex align-items-center mt-2 ps-3">
                      <img src="../../../assets/coins_icon/DOT 1.svg" alt="" />
                      <p class="mb-0 coin_abb ms-3 me-3">DOT</p>
                      <p class="mb-0 coin_name">Polkadot</p>
                    </div>
                  </th>
                  <td class="last_price">₦ 31.08</td>
                  <td class="price_change negative">-11.31%</td>
                  <td>
                    <img
                      src="../../../assets/coins_icon/Screenshot 2021-02-28 at 15.09 1.svg"
                      alt=""
                    />
                  </td>
                  <td class="d-flex justify-content-center">
                    <button class="buy_btn">Buy</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
