<div class="wrapper start_bg">
  <div class="row">
    <div class="col-md-10 col-lg-8 start_margin mx-auto">
      <h1 class="start_title">Start trading today</h1>
      <div class="d-flex align-items-center justify-content-center mt-5">
        <button class="register_btn">Register</button>
        <button class="trade_btn ms-2">Trade Now</button>
      </div>
    </div>
  </div>
</div>
