import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './home/header/header.component';
import { CurrencyDashboardComponent } from './home/currency-dashboard/currency-dashboard.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { IndexComponent } from './home/index/index.component';
import { TrustedPlatformComponent } from './home/trusted-platform/trusted-platform.component';
import { GetInTouchComponent } from './home/get-in-touch/get-in-touch.component';
import { StartTradingComponent } from './layout/start-trading/start-trading.component';
import { AboutHeaderComponent } from './about/about-header/about-header.component';
import { AboutIndexComponent } from './about/about-index/about-index.component';
import { ShowcaseComponent } from './about/showcase/showcase.component';
import { GlobalFreedomComponent } from './about/global-freedom/global-freedom.component';
import { AboutContactComponent } from './about/about-contact/about-contact.component';
import { FutureTechComponent } from './about/future-tech/future-tech.component';
import { ContactIndexComponent } from './contact/contact-index/contact-index.component';
import { ContactFormComponent } from './contact/contact-form/contact-form.component';
import { LoginIndexComponent } from './login/login-index/login-index.component';
import { LoginSectionComponent } from './login/login-section/login-section.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CurrencyDashboardComponent,
    FooterComponent,
    NavbarComponent,
    IndexComponent,
    TrustedPlatformComponent,
    GetInTouchComponent,
    StartTradingComponent,
    AboutHeaderComponent,
    AboutIndexComponent,
    ShowcaseComponent,
    GlobalFreedomComponent,
    AboutContactComponent,
    FutureTechComponent,
    ContactIndexComponent,
    ContactFormComponent,
    LoginIndexComponent,
    LoginSectionComponent,
  ],
  imports: [BrowserModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
